import React from 'react';
import { SearchBar } from '..';
import { GoBack } from '../../Common';

import * as S from '../style';

const PSearchHeader = ({ text, period, reg, setText, handleP, handleR }) => {
  return (
    <S.ShContainer>
      <S.ShTop>
        <GoBack />
        <SearchBar text={text} period={period} reg={reg} setText={setText} />
      </S.ShTop>
      <S.ShBottom>
        <S.ShRegWrapper regStatus={reg}>
          <S.ShRegBtn value="prereg" onClick={handleR}>
            수강희망
            <div> </div>
          </S.ShRegBtn>
          <S.ShRegBtn value="register" onClick={handleR}>
            수강신청
            <div> </div>
          </S.ShRegBtn>
        </S.ShRegWrapper>
        <S.ShFilterWrapper>
          <S.ShFilter value={period} onChange={handleP}>
            <option value="20251">2025년 1학기</option>
            <option value="20242">2024년 2학기</option>
            <option value="20241">2024년 1학기</option>
            <option value="20232">2023년 2학기</option>
            <option value="20231">2023년 1학기</option>
            <option value="20222">2022년 2학기</option>
            <option value="20221">2022년 1학기</option>
            <option value="20212">2021년 2학기</option>
          </S.ShFilter>
        </S.ShFilterWrapper>
      </S.ShBottom>
    </S.ShContainer>
  );
};
export default PSearchHeader;
