import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { FiChevronDown, FiX } from 'react-icons/fi';
import * as S from './style';
import { getClasApi } from '../../utils/class';
import clasList from '../../utils/data/clasList';
import { checkPresent } from '../../utils/checkPresent';
import { checkRegBlock } from '../../utils/checkRegBlock';
import { BLOCK_ALERT, REG, TERM, YEAR } from '../../constants/DEFAULT_VALUE';

function AdvancedSearchModal({ setModalSwitch, searchInfo }) {
  const history = useHistory();

  const [advInfo, setAdvInfo] = useState({
    term: YEAR + TERM,
    reg: REG,
    clas: 0,
    cour: 'all',
    col: '',
    dept: '',
    search: 'all',
    query: '',
    preCode: '',
    sufCode: '',
  });
  // const [clasList, setClasList] = useState(false);

  // const getClasList = async () => {
  //   const [data, error] = await getClasApi();
  //   console.log(data);
  //   if (data) {
  //     setClasList({ ...data });
  //     console.log('여기');
  //   } else {
  //     alert('에러가 발생하였습니다.');
  //     setModalSwitch(false);
  //     // eslint-disable-next-line no-console
  //     console.dir(error);
  //   }
  // };

  const reset = () => {
    setAdvInfo({
      term: '',
      reg: '',
      clas: 0,
      cour: 'all',
      col: '',
      dept: '',
      search: 'all',
      query: '',
      preCode: '',
      sufCode: '',
    });
  };

  const handleAdvInfo = (e) => {
    const { id, value } = e.target;
    setAdvInfo({
      ...advInfo,
      [id]: value,
    });
    if (id === 'cour') {
      if (value === '30' || value === '41' || value === '71') {
        setAdvInfo({
          ...advInfo,
          [id]: value,
          clas: clasList[value].id,
          col: '',
          dept: '',
        });
      } else if (value === 'all') {
        setAdvInfo({
          ...advInfo,
          clas: 0,
          cour: 'all',
          col: '',
          dept: '',
        });
      } else {
        setAdvInfo({
          ...advInfo,
          [id]: value,
          col: '',
          dept: '',
        });
      }
    }
    if (id === 'col') {
      if (advInfo.cour === '01') {
        setAdvInfo({
          ...advInfo,
          [id]: value,
          clas: clasList[advInfo.cour].data[value].id,
          dept: '',
        });
      } else {
        setAdvInfo({
          ...advInfo,
          [id]: value,
          dept: '',
        });
      }
    }
    if (id === 'dept') {
      setAdvInfo({
        ...advInfo,
        [id]: value,
        clas: clasList[advInfo.cour].cols[advInfo.col].data[value].id,
      });
    }
    if (id === 'search') {
      setAdvInfo({
        ...advInfo,
        [id]: value,
        query: '',
        preCode: '',
        sufCode: '',
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // 수희등 기간에 현학기 수신 블락
    if (
      checkPresent(advInfo.term.substring(0, 4), advInfo.term.substring(4)) &&
      checkRegBlock(advInfo.reg)
    ) {
      alert(BLOCK_ALERT);
      return;
    }

    let query = '';
    // console.log('advanced search modal', advInfo);
    if (advInfo.search !== 'all') {
      if (advInfo.queryBy === 'code') {
        query = `&queryBy=${advInfo.search}&query=${advInfo.preCode}-${advInfo.sufCode}`;
      } else {
        query = `&queryBy=${advInfo.search}&query=${advInfo.query}`;
      }
    }
    history.push(
      `/advanced-search/${advInfo.reg}?year=${Number(
        advInfo.term.substring(0, 4),
      )}&term=${Number(advInfo.term.substring(4))}&clas=${
        advInfo.clas
      }${query}`,
    );
    window.location.reload();
  };

  useEffect(() => {
    // getClasList();
    if (searchInfo) {
      setAdvInfo(searchInfo);
      // console.log(searchInfo);
    }
  }, []);
  // console.log(advInfo);
  // console.log(clasList);
  return createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {clasList && (
        <S.Backdrop>
          <S.Modal>
            <S.ModalTitle>
              <S.SplitBox />
              <p>상세 검색</p>
              <S.XBox>
                <FiX onClick={() => setModalSwitch(false)} />
              </S.XBox>
            </S.ModalTitle>
            <S.ModalContent>
              <S.AsmForm onSubmit={handleSubmit}>
                <S.AsmTwoWrapper>
                  <S.AsmSmlWrapper>
                    <S.AsmLabel htmlFor="term">학기</S.AsmLabel>
                    <S.AsmDownIconOne />
                    <S.AsmSmlSelect
                      id="term"
                      value={advInfo.term}
                      onChange={handleAdvInfo}
                      required
                    >
                      <option value="" disabled hidden>
                        년도 학기
                      </option>
                      <option value="20251">2025년 1학기</option>
                      <option value="20242">2024년 2학기</option>
                      <option value="20241">2024년 1학기</option>
                      <option value="20232">2023년 2학기</option>
                      <option value="20231">2023년 1학기</option>
                      <option value="20222">2022년 2학기</option>
                      <option value="20221">2022년 1학기</option>
                      <option value="20212">2021년 2학기</option>
                    </S.AsmSmlSelect>
                  </S.AsmSmlWrapper>
                  <S.AsmSmlDiv />
                  <S.AsmSmlWrapper>
                    <S.AsmLabel htmlFor="reg">수강희망/신청</S.AsmLabel>
                    <S.AsmDownIconOne />
                    <S.AsmSmlSelect
                      id="reg"
                      value={advInfo.reg}
                      onChange={handleAdvInfo}
                      required
                    >
                      <option value="" disabled hidden>
                        수강희망/신청
                      </option>
                      <option value="prereg">수강희망</option>
                      <option value="register">수강신청</option>
                    </S.AsmSmlSelect>
                  </S.AsmSmlWrapper>
                </S.AsmTwoWrapper>
                <S.AsmOneWrapper>
                  <S.AsmLabel htmlFor="cour">이수 구분</S.AsmLabel>
                  <S.AsmDownIconOne />
                  <S.AsmBigSelect
                    id="cour"
                    value={advInfo.cour}
                    onChange={handleAdvInfo}
                  >
                    <option value="all">전체</option>
                    {Object.keys(clasList).map((cour) => (
                      <option value={cour} key={cour}>
                        {clasList[cour].courNm}
                      </option>
                    ))}
                  </S.AsmBigSelect>
                  {/* 전공 및 학문의기초 */}
                  {(advInfo.cour === '00' || advInfo.cour === '24') && (
                    <S.AsmHiddenTwoWrapper>
                      <S.AsmSmlWrapper>
                        <S.AsmLabel htmlFor="col" hidden>
                          단과대학
                        </S.AsmLabel>
                        <S.AsmDownIconTwo />
                        <S.AsmSmlSelect
                          id="col"
                          onChange={handleAdvInfo}
                          value={advInfo.col}
                          required
                        >
                          <option value="" disabled hidden>
                            단과대학
                          </option>
                          {advInfo.cour === 'all' ||
                            Object.keys(clasList[advInfo.cour].cols).map(
                              (col) => (
                                <option value={col} key={col}>
                                  {clasList[advInfo.cour].cols[col].colNm}
                                </option>
                              ),
                            )}
                        </S.AsmSmlSelect>
                      </S.AsmSmlWrapper>
                      <S.AsmSmlDiv />
                      <S.AsmSmlWrapper>
                        <S.AsmLabel htmlFor="dept" hidden>
                          학과
                        </S.AsmLabel>
                        <S.AsmDownIconTwo />
                        <S.AsmSmlSelect
                          id="dept"
                          onChange={handleAdvInfo}
                          value={advInfo.dept}
                          required
                        >
                          <option value="" disabled hidden>
                            학과
                          </option>
                          {advInfo.col &&
                            Object.keys(
                              clasList[advInfo.cour].cols[advInfo.col].data,
                            ).map((dept) => (
                              <option value={dept} key={dept}>
                                {
                                  clasList[advInfo.cour].cols[advInfo.col].data[
                                    dept
                                  ].deptNm
                                }
                              </option>
                            ))}
                        </S.AsmSmlSelect>
                      </S.AsmSmlWrapper>
                    </S.AsmHiddenTwoWrapper>
                  )}
                  {/* 교양 */}
                  {advInfo.cour === '01' && (
                    <>
                      <S.AsmLabel htmlFor="col" hidden>
                        단과대학
                      </S.AsmLabel>
                      <S.AsmDownIconTwo />
                      <S.AsmHiddenOneSelect
                        id="col"
                        onChange={handleAdvInfo}
                        value={advInfo.col}
                        required
                      >
                        <option value="" disabled hidden>
                          교양 분류
                        </option>
                        {Object.keys(clasList[advInfo.cour].data).map((col) => (
                          <option value={col} key={col}>
                            {clasList[advInfo.cour].data[col].colNm}
                          </option>
                        ))}
                      </S.AsmHiddenOneSelect>
                    </>
                  )}
                </S.AsmOneWrapper>
                <S.AsmOneWrapper>
                  <S.AsmLabel htmlFor="search">검색어</S.AsmLabel>
                  <S.AsmDownIconOne />
                  <S.AsmBigSelect
                    id="search"
                    value={advInfo.search}
                    onChange={handleAdvInfo}
                  >
                    <option value="all">전체</option>
                    <option value="name">과목명</option>
                    <option value="prof">교수명</option>
                    <option value="code">학수번호</option>
                  </S.AsmBigSelect>
                  {(advInfo.search === 'name' || advInfo.search === 'prof') && (
                    <S.AsmQuery
                      id="query"
                      value={advInfo.query.replace(/ /g, '')}
                      onChange={handleAdvInfo}
                      placeholder="검색어 입력"
                      required
                    />
                  )}
                  {advInfo.search === 'code' && (
                    <S.AsmTwoWrapper>
                      <S.AsmSmlWrapper>
                        <S.AsmQuery
                          id="preCode"
                          value={advInfo.preCode.replace(/ /g, '')}
                          onChange={handleAdvInfo}
                          placeholder="학수번호"
                          maxLength={7}
                          required
                        />
                      </S.AsmSmlWrapper>
                      <S.AsmSmlDiv />
                      <S.AsmSmlWrapper>
                        <S.AsmQuery
                          id="sufCode"
                          value={advInfo.sufCode.replace(/ /g, '')}
                          onChange={handleAdvInfo}
                          placeholder="분반"
                          maxLength={2}
                          required
                        />
                      </S.AsmSmlWrapper>
                    </S.AsmTwoWrapper>
                  )}
                </S.AsmOneWrapper>
                <S.AsmBtnWrapper>
                  <S.AsmResetBtn onClick={reset}>
                    <img
                      src="https://ku-sugang.s3.ap-northeast-2.amazonaws.com/icon/refresh.svg"
                      alt="refresh"
                    />
                    초기화
                  </S.AsmResetBtn>
                  <S.AsmSearchBtn className="advancedSearch" type="submit">
                    검색하기
                  </S.AsmSearchBtn>
                </S.AsmBtnWrapper>
              </S.AsmForm>
            </S.ModalContent>
          </S.Modal>
        </S.Backdrop>
      )}
    </>,
    document.getElementById('modal'),
  );
}

export default AdvancedSearchModal;
