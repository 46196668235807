import React, { useState, useEffect } from 'react';
import { getClassPeriodApi, getUserInfoSumApi } from '../../../utils';
import PMainHeader from './PMainHeader';

const MainHeader = () => {
  const [text, setText] = useState('');
  const [periodList, setPeriodList] = useState({});
  const [userInfoSum, setUserInfoSum] = useState({ grade: null, nickname: '' });

  const getGrade = async () => {
    const [data, error] = await getUserInfoSumApi();
    if (error) {
      // alert('에러가 발생했습니다. 다시 로그인 해주세요.');
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      window.location.href = `/account/login/`;
    }
    if (data.grade) {
      setUserInfoSum({ ...data });
    }
    getPeriod(data.grade);
  };

  const getPeriod = async (userGrade) => {
    const res = await getClassPeriodApi();
    if (res[0]) {
      const { state } = res[0];
      const copy = { ...periodList };
      copy.msg = res[0].msg;
      if (state === 'OP') {
        copy.time = '1.20(월) 10:00 - 2.3(월) 10:00';
        copy.date = '1월 20일 - 2월 3일은';
      } else if (state === 'RP') {
        copy.time = '2.4(화) 13:00 - 2.6(목) 12:00';
        copy.date = '2월 4일 - 6일은';
      } else if (state === 'IP') {
        copy.time = '2.10(월) 10:00 - 2.11(화) 08:30';
        copy.date = '2월 10일 - 11일은';
      } else if (state === 'LP') {
        copy.time = '2.11(화) 10:00 - 2.12(수) 09:00';
        copy.date = '2월 11일 - 12일은';
      } else if (state === 'JP') {
        copy.time = '2.12(수) 10:00 - 2.13(목) 09:00';
        copy.date = '2월 12일 - 13일은';
      } else if (state === 'SP') {
        copy.time = '2.13(목) 10:00 - 2.14(금) 09:00';
        copy.date = '2월 13일 - 14일은';
      } else if (state === 'FP') {
        copy.time = '2.14(금) 10:00 - 2.15(토) 09:00';
        copy.date = '2월 14일 - 15일은';
      } else if (state === 'TP') {
        copy.time = '2.25(화) 10:00 - 2.26(수) 12:00';
        copy.date = '2월 25일 - 26일은';
      } else if (
        state === 'GL' ||
        state === 'GJ' ||
        state === 'GS' ||
        state === 'GF'
      ) {
        // eslint-disable-next-line prefer-destructuring
        copy.msg = res[0].msg.split(')')[1];
        if (userGrade === 1) {
          copy.time = '[1학년]  3.5(수) 21:30 - 3.6(목) 12:00';
        } else if (userGrade === 2) {
          copy.time = '[2학년]  3.5(수) 20:30 - 3.6(목) 12:00';
        } else if (userGrade === 3) {
          copy.time = '[3학년]  3.5(수) 19:30 - 3.6(목) 12:00';
        } else if (userGrade === 4) {
          copy.time = '[4학년]  3.5(수) 18:30 - 3.6(목) 12:00';
        } else {
          copy.time = '[4학년]  3.5(수) 18:30 - 3.6(목) 12:00';
        }
        copy.date = '3월 5일 - 6일은';
      } else if (state === 'AP') {
        copy.time = '3.6(목) 18:30 - 3.7(금) 12:00';
        copy.date = '3월 6일 - 7일은';
      } else {
        copy.time = '달력을 누르면 전체 일정을 확인할 수 있습니다.';
        copy.date = '지금은';
      }
      setPeriodList(copy);
    }
  };

  useEffect(() => {
    getGrade();
  }, []);

  return (
    <PMainHeader
      text={text}
      setText={setText}
      userInfoSum={userInfoSum}
      periodList={periodList}
    />
  );
};

export default React.memo(MainHeader);
