import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { FiChevronRight, FiChevronLeft, FiX } from 'react-icons/fi';
import * as S from './style';
import { YEAR, TERM } from '../../constants/DEFAULT_VALUE';

function CalendarModal({ grade, setModalSwitch }) {
  const month = new Date().getMonth(); // 현재 월
  // 0부터 시작하므로 2, 8월에는 1 / 3, 9월에는 0
  const [idx, setIdx] = useState(month === 2 || month === 8 ? 0 : 1);
  return createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <S.Backdrop>
        <S.Modal style={{ maxWidth: '380px' }}>
          <S.ModalTitle>
            <S.SplitBox />
            <p>수강신청 일정</p>
            <S.XBox>
              <FiX onClick={() => setModalSwitch(false)} />
            </S.XBox>
          </S.ModalTitle>
          <S.ModalHeader>
            {/* {idx === 1 ? (
              <>
                <S.SplitBox />
                <p>2025년 2월</p>
                <FiChevronRight onClick={() => setIdx(idx - 1)} />
              </>
            ) : (
              <>
                <FiChevronLeft onClick={() => setIdx(idx + 1)} />
                <p>2025년 3월</p>
                <S.SplitBox />
              </>
            )} */}
          </S.ModalHeader>
          <S.CalendarContent>
            <S.CalendarImg
              src={`https://ku-sugang.s3.ap-northeast-2.amazonaws.com/calendar/${YEAR}-${TERM}/default.png`}
            />
            {/* {idx === 1 ? (
              <S.CalendarImg
                src={`https://ku-sugang.s3.ap-northeast-2.amazonaws.com/calendar/${YEAR}-${TERM}/${
                  grade || 0
                }.png`}
              />
            ) : (
              <S.CalendarImg
                src={`https://ku-sugang.s3.ap-northeast-2.amazonaws.com/calendar/${YEAR}-${TERM}/5.png`}
              />
            )} */}
          </S.CalendarContent>
        </S.Modal>
      </S.Backdrop>
    </>,
    document.getElementById('modal'),
  );
}

export default CalendarModal;
